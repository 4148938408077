/* Custom CSS styles and classes */

/* Table styles */
.table td.fit-width,
.table th.fit-width {
  white-space: nowrap;
  width: 1%;
}

/* START Pagination style overrides */
.pagination > li > a {
  background-color: white;
  color: #6c757d;
}

.pagination > li > a:hover,
.pagination > li > span:hover {
  /* secondary button style */
  color: white;
  background-color: #6c757d;
  border-color: #6c757d;
}

/* secondary button focus style */
.pagination > li > a:focus,
.pagination > li > span:focus {
  box-shadow: 0 0 0 0.2rem rgb(108, 117, 125, 0.5) !important;
  z-index: 4;
  position: relative;
}

/* Current page style */
.pagination > li.active > span,
.pagination > li.active > a {
  color: #6c757d !important;
  background-color: white !important;
  border: 1px solid #dee2e6 !important;
}

.pagination > li.disabled > span,
.pagination > li.disabled > a {
  color: rgb(108, 117, 125, 0.5) !important;
}
/* END Pagination style overrides */
