/* Loading modal customizations */
.loading-modal-backdrop {
  z-index: 5000 !important;
}

.loading-modal {
  z-index: 5010 !important;
}

.loading-modal-dialog {
  z-index: 5020 !important;
}

/* Start SGI loading animation */
@keyframes pound {
  25% {
    transform: scale(1.5);
    background: #646464;
    box-shadow: 1px 2px 3px 0 rgba(249, 247, 251, 0.65),
      2px 6px 12px 0 rgba(249, 247, 251, 0.5),
      3px 8px 15px 0 rgba(249, 247, 251, 0.45);
  }

  50% {
    transform: scale(1);
    background: #c7c7c7;
    box-shadow: none;
  }

  75% {
    background: #e7e7e7;
  }

  100% {
    background: #e7e7e7;
  }
}

ul.loader {
  display: block;
  position: relative;
  width: 6em;
  margin: 50px auto;
  transform: skewX(-14deg);
}

.loader li {
  list-style: none;
  display: block;
  float: left;
  width: 0.6em;
  height: 2.5em;
  margin: 0 0.3em 0 0;
  border-top-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background: #e7e7e7;
  animation: pound 1.5s ease-in-out infinite;
  animation-delay: 0.25s;
  transform-origin: center;
}

.loader li:nth-child(2) {
  animation-delay: 0.5s;
  width: 0.5em;
  margin: 0 0.3em 0 0;
}

.loader li:nth-child(3) {
  animation-delay: 0.75s;
  width: 0.4em;
  margin: 0 0.4em 0 0;
}

.loader li:nth-child(4) {
  animation-delay: 1s;
  width: 0.3em;
  margin: 0 0.5em 0 0;
}
/* End SGI loading animation */
